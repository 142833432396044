import React, { useState } from "react"
import MainHeader from "../main_header/main_header"
import MainFooter from "../footer/main_footer"
import loadable from "@loadable/component"
const Banner = loadable(() => import("./banner/banner"), {
  fallback: <div></div>,
})
const Card = loadable(() => import("./service_card/card"), {
  fallback: <div></div>,
})
const Services = loadable(() => import("./services"), {
  fallback: <div></div>,
})

export default function ServicesMain() {
  const [onHover, setonHover] = useState(false)
  const [serviceName, setserviceName] = useState("")

  return (
    <React.Fragment>
      <MainHeader />
      <Banner />
      <Card />

      <Services />
      <MainFooter />
    </React.Fragment>
  )
}
